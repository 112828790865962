@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;600;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.whomField {
  @apply text-[10px] font-300 text-[#000] w-full h-6 pl-[30px] bg-[#E5E5EF40];
  border-radius: var(--shadow-y, 2px);
  border: 0.1px solid var(--textbox-border, #000);
}

body {
  @apply bg-white dark:bg-black;
  font-family: "Roboto", sans-serif;
}

main {
  @apply bg-[#E5E5EF] sm:bg-white dark:sm:bg-black mt-[24px] sm:mt-[42px] xl:mt-[62px] p-[5px_5px_64px_5px] md:p-[0px_0px_0px_0px];
}

/* responsive */
.darkMode {
  @apply hidden dark:block;
}

.lightMode {
  @apply block dark:hidden;
}

.forMobile {
  /* for mobile */
  @apply block sm:hidden;
}

.forTab {
  /* for tablets */
  @apply hidden sm:block lg:hidden;
}

.forLaptop {
  /* for laptop */
  @apply hidden lg:block;
}

.forDesktop {
  /* for desktop */
  @apply hidden xl:block;
}

.forNonDesktop {
  /* for non desktop */
  @apply block xl:hidden;
}

.forNonMobile {
  /* for non mobile */
  @apply hidden sm:block;
}

.forTabLaptopDesktop {
  @apply hidden sm:block;
}

/* tags */
h1 {
  @apply font-roboto leading-normal text-16 text-black dark:text-white;
}

h2 {
  @apply font-roboto leading-normal text-14 text-black dark:text-white;
}

h3 {
  @apply font-roboto leading-normal text-12 font-400 text-black dark:text-white;
}

h4 {
  @apply font-roboto leading-normal text-11 font-400 text-black dark:text-white;
}

h5 {
  @apply font-roboto leading-normal text-10 font-400 text-black dark:text-white;
}

h6 {
  @apply font-roboto leading-normal text-9 font-400 text-black dark:text-white;
}

.leftColHeader {
  @apply border-r border-[#0000001A] dark:border-[#FFFFFF40] sm:pt-[25px] xl:pt-[25px] sm:pr-[10px] xl:pr-[40px] sm:mr-[10px] xl:mr-[40px];
}

.ColClass1 {
  @apply col-span-1 forDesktop;
}

.ColClass2 {
  @apply col-span-12 sm:col-span-5 md:col-span-5 lg:col-span-5 xl:col-span-4 forTabLaptopDesktop;
}

.ColClass3 {
  @apply col-span-12 sm:col-span-7 md:col-span-7 lg:col-span-7 xl:col-span-5;
}

.ColClass4 {
  @apply col-span-2 forDesktop;
}

.drpDownContent {
  @apply font-roboto leading-normal text-12 font-400 text-black dark:text-white;
}

/* new */
.dialogClass {
  @apply bg-[#E5E5EF] dark:bg-[#000] dark:border dark:border-white w-full md:w-[250px] h-fit py-[5px] px-[10px] flex flex-col items-start gap-[10px];
}

.headerDrpDown {
  border: var(--Sharp-line-width, 0.5px) solid
    var(--default-border, rgba(0, 0, 0, 0.1));
}

.rating {
  @apply w-[60px] h-[22px] px-[15px] py-[5px] rounded-[5px] bg-[#E5E5EF] text-[#000] text-[10px] font-400 leading-normal;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.rating:hover {
  @apply w-[60px] h-[22px] px-[15px] py-[5px] rounded-[5px] bg-[#E5E5EF] text-[#4169E1] text-[10px] font-400 leading-normal;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.buyMore {
  @apply w-[75px] h-[22px] px-[15px] py-[5px] rounded-[5px] text-[#000] text-[10px] font-600 leading-normal;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  background: var(--qBlue50, rgba(65, 105, 225, 0.5));
}

.buyMoreOn {
  @apply w-[116px] h-[22px] px-[15px] py-[5px] rounded-[5px] text-[10px] font-600 leading-normal;
  border: var(--default-line-width, 1px) solid var(--button-bg, #e5e5ef);
  color: var(--qBlue50, rgba(65, 105, 225, 0.5));
}

.personCard {
  @apply w-full lg:w-full cursor-pointer flex items-center justify-between lg:justify-normal p-[5px] m-auto rounded-[5px] bg-white dark:bg-black;
}
/* .persona {
  @apply !w-[300px] m-auto;
} */
.persona.intelligentBorder {
  @apply flex w-full rounded-[5px];
  border-bottom: 0.5px solid #6dee2b;
}
.persona.stupidBorder {
  @apply flex w-full rounded-[5px];
  border-bottom: 0.5px solid #ee4b2b;
}
.persona.banditBorder {
  @apply flex w-full rounded-[5px];
  border-bottom: 0.5px solid #eead2b;
}
.persona.helplessBorder {
  @apply flex w-full rounded-[5px];
  border-bottom: 0.5px solid #eead2b;
}
.persona.neutralBorder {
  @apply flex w-full rounded-[5px];
  border-bottom: 0.5px solid #d3d3d3;
}
.post.intelligentBorder {
  box-shadow: var(--default-line-width, 1px) var(--shadow-y, 2px)
    var(--shadow-blur, 0px) var(--shadow-spread, 0px)
    var(--Intelligent10, rgba(109, 238, 43, 0.1));
}
.post.stupidBorder {
  box-shadow: var(--default-line-width, 1px) var(--shadow-y, 2px)
    var(--shadow-blur, 0px) var(--shadow-spread, 0px)
    var(--Stupid10, rgba(238, 75, 43, 0.1));
}
.post.banditBorder {
  box-shadow: var(--default-line-width, 1px) var(--shadow-y, 2px)
    var(--shadow-blur, 0px) var(--shadow-spread, 0px)
    var(--Helpess10, rgba(238, 173, 43, 0.1));
}
.post.helplessBorder {
  box-shadow: var(--default-line-width, 1px) var(--shadow-y, 2px)
    var(--shadow-blur, 0px) var(--shadow-spread, 0px)
    var(--Helpess10, rgba(238, 173, 43, 0.1));
}
.post.neutralBorder {
  box-shadow: var(--default-line-width, 1px) var(--shadow-y, 2px)
    var(--shadow-blur, 0px) var(--shadow-spread, 0px)
    var(--Unrated10, rgba(0, 0, 0, 0.1));
}
.registrationCard {
  @apply bg-[#fff] dark:bg-[#000] rounded-[5px] py-[20px] px-[5px] flex flex-col gap-[10px] sm:min-w-[310px] sm:max-w-[310px];
}

.registrationCard .inputField {
  @apply rounded-[2px] h-[20px] px-[15px] py-[2px] text-[10px] font-300 text-[#000] dark:text-[#fff] bg-[#E5E5EF40];
  border: 0.1px solid var(--textbox-border, #000);
}

.searchField {
  @apply rounded-[2px] bg-[#E5E5EF40];
  border: 0.1px solid var(--textbox-border, #000);
}

.btnBlue {
  @apply h-[22px] rounded-[5px] text-[10px] font-600 text-black;
  background: var(--qBlue50, rgba(65, 105, 225, 0.5));
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.addButton {
  @apply h-[22px] rounded-[5px] text-[10px] font-600;
  background: var(--qBlue50, rgba(65, 105, 225, 0.5));
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.addButton.active {
  @apply h-[22px] rounded-[5px] text-[10px] font-400;
  color: var(--qBlue50, rgba(65, 105, 225, 0.5));
  background: none;
  box-shadow: none;
}

.btnGray {
  @apply px-[15px] py-[5px] rounded-[5px] text-[10px] font-400 bg-[#E5E5EF] leading-normal;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  transition: background-color 300ms ease-out, transform 300ms ease-out;
}

.btnFlag {
  @apply w-[50px] my-auto rounded-[5px] p-[5px_15px] text-[10px] font-400 leading-normal bg-[#e5e5ef];
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

/* Light Mode */
.personaCard {
  @apply rounded-[5px];
}
.postCard {
  @apply w-full h-fit p-[5px] rounded-[10px] cursor-pointer bg-white dark:bg-black;
  /* sm:w-[560px] */
}
.quadCard {
  border-radius: var(--corner-round, 5px);
  background: var(--Post-bg, #fff);
  border-bottom: 0.5px solid var(--default-border, rgba(0, 0, 0, 0.1));
}
.voteCard {
  border-radius: var(--corner-round, 5px);
  border: 0.5px solid var(--VoteQuadrant-border, #000);
}
.commentField {
  border-radius: var(--shadow-y, 2px);
  /* border: 0.1px solid var(--textbox-border, #000); */
  border: 0.5px solid var(--default-border, rgba(0, 0, 0, 0.1));
  @apply rounded-[2px] !bg-[#E5E5EF40];
}
.dividerBorder {
  border-bottom: 0.5px solid var(--default-border, rgba(0, 0, 0, 0.1));
}
@media (prefers-color-scheme: dark) {
  .personaCard {
  }
  .postCard {
  }
  .quadCard {
    border-radius: var(--corner-round, 5px);
    background: var(--Post-bg, #000);
    border-bottom: 0.5px solid var(--default-border, rgba(0, 0, 0, 0.1));
  }
  .voteCard {
    border-radius: var(--corner-round, 5px);
    border: 0.5px solid var(--VoteQuadrant-border, #fff);
  }
  .commentField {
    @apply rounded-[2px] !bg-[#E5E5EF40];
    border: 0.1px solid var(--textbox-border, #fff);
  }
}
